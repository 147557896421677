import { Controller } from "@hotwired/stimulus";

///////////////////////////////////
// Implements color picker
///////////////////////////
export default class extends Controller {
  connect() {
    var parent = this.element;

    parent.querySelectorAll("input").forEach(function (el) {
      el.addEventListener("click", event => {
        var colors = el.value.split("_");
        if (colors.length == 2) {
          document.getElementById("spec_color1").value = colors[0];
          document.getElementById("spec_color2").value = colors[1];
        } else {
          document.getElementById("spec_color").value = colors[0];
        }
      });
    });
  }
}
