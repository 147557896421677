import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static values = { url: String }

  connect() {
    var checkUrl = this.urlValue;

    $(this.element).on('change', function(){
      var spinner = `
        <div class="d-flex align-items-center">
          <small class="spinner">
            <div class="spinner-border">
              <span class="sr-only"></span>
            </div>
          </small>
          <span class="font-size-1 ml-2">Validating the domain ...</span>
        </div>
      `
      $('#status-check').html(spinner);
      $.get(checkUrl, {domain: $(this).val()});
    })
  }
}
