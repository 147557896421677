import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  connect() {
    var unfold = new HSUnfold('.js-hs-unfold-invoker').init();

    (function() {
      if(window.innerWidth > 768){
        new Glider(document.querySelector('.glider'), {
          slidesToShow: 1,
          draggable: false,
          dots: '.glider-dots',
          arrows: {
            prev: '.glider-prev',
            next: '.glider-next'
          }
        });
      }
    })();
  }
}
