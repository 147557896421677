import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static values = {
    email: String,
    uuid: String
  };

  connect() {
    posthog.identify(this.uuidValue, { email: this.emailValue });
  }
}
