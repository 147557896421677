import { Controller } from "@hotwired/stimulus";


export default class extends Controller {
  static values = {
    stripePublicKey: String,
    subscribeUrl: String,
    planName: String,
    dashboardUrl: String
  };

  connect() {
    var stripePublicKey = this.stripePublicKeyValue;
    var subscribeUrl = this.subscribeUrlValue;
    var planName = this.planNameValue;
    var dashboardUrl = this.dashboardUrlValue;

    if(typeof Stripe === 'function'){
      initStripe();
    }else{
      var script = document.createElement('script');
      script.onload = function () {
        initStripe();
      };
      script.src = "https://js.stripe.com/v3/";
      document.head.appendChild(script);
    }

    function initStripe(){
      var stripe = Stripe(stripePublicKey);
      document.querySelector("#payment-submit").disabled = true;
      
      var elements = stripe.elements();
      var style = {
        base: {
          fontFamily: '"Open Sans", sans-serif',
          fontSmoothing: "antialiased",
          fontSize: "16px",
          color: "#32325d",
          "::placeholder": {
            color: "#677788"
          }
        },
        invalid: {
          color: "#fa755a",
          iconColor: "#fa755a"
        }
      };

      var card = elements.create("card", { style: style });
      card.mount("#card-element");

      card.on("change", function (event) {
        if(event.error){
          displayError(event.error.message);
          document.querySelector("#payment-submit").disabled = true;
        } else{
          hideError();
          document.querySelector("#payment-submit").disabled = false;
        }
      });

      var btn = document.getElementById("payment-submit");
      btn.addEventListener("click", function(event) {
        hideError();
        showLoading('Processing payment ...');
        createPaymentMethod();
      });

      function createPaymentMethod() {
        stripe
          .createPaymentMethod({
            type: 'card',
            card: card,
            billing_details: {
              name: planName,
            },
          })
          .then((result) => {
            if (result.error) {
              displayError(result.error.message);
              hideLoading();
            } else {
              createSubscription({
                paymentMethodId: result.paymentMethod.id
              });
            }
          });
      }
  
      function createSubscription({paymentMethodId}) {
        return (
          fetch(subscribeUrl, {
            method: 'post',
            headers: {
              'Content-type': 'application/json',
            },
            body: JSON.stringify({
              payment_method_id: paymentMethodId
            }),
          })
            .then((response) => {
              return response.json();
            })
            .then((result) => {
              if (result.error) {
                throw result;
              }
              return result;
            })
            .then((result) => {
              return {
                paymentMethodId: paymentMethodId,
                subscription: result
              };
            })
            // Some payment methods require a customer to be on session
            // to complete the payment process. Check the status of the
            // payment intent to handle these actions.
            .then(handlePaymentThatRequiresCustomerAction)
            .then(onSubscriptionComplete)
            .catch((error) => {
              displayError(error.error.message);
            })
        );
      }
  
      function handlePaymentThatRequiresCustomerAction({
        subscription,
        invoice,
        paymentMethodId,
        isRetry,
      }) {
        if (subscription && (subscription.status === 'active' || subscription.status === 'succeeded')) {
          return { subscription, paymentMethodId };
        }

        // If it's a first payment attempt, the payment intent is on the subscription latest invoice.
        // If it's a retry, the payment intent will be on the invoice itself.
        let paymentIntent = invoice ? invoice.payment_intent : subscription.latest_invoice.payment_intent;
      
        if (
          paymentIntent.status === 'requires_action' ||
          (isRetry === true && paymentIntent.status === 'requires_payment_method')
        ) {
          return stripe
            .confirmCardPayment(paymentIntent.client_secret, {
              payment_method: paymentMethodId,
            })
            .then((result) => {
              if (result.error) {
                throw result;
              } else {
                if (result.paymentIntent.status === 'succeeded') {
                 // subscription = Stripe::Subscription.retrieve(subscription.id)
                  subscription.status = 'active';
                  return {
                    subscription: subscription,
                    invoice: invoice,
                    paymentMethodId: paymentMethodId,
                  };
                }
              }
            })
            .catch((error) => {
              displayError(error.message);
            });
        } else {
          return { subscription, priceId, paymentMethodId };
        }
      }
  
      function onSubscriptionComplete(result) {
        if(result.subscription.status === 'active' || result.subscription.status === 'succeeded'){
          document.querySelector("#payment-spinner").classList.add("hidden");
          document.querySelector("#success-message").classList.remove("hidden");
          window.location.href = dashboardUrl;
        }
      }
  
      var displayError = function(errorMsgText) {
        hideLoading();
        
        var errorMsg = document.querySelector("#card-error");
        errorMsg.textContent = errorMsgText;
        errorMsg.classList.remove('hidden');
      };
  
      var hideError = function(){
        var errorMsg = document.querySelector("#card-error");
        errorMsg.classList.add('hidden');
      }
  
      var showLoading = function(message) {
        document.querySelector("#payment-submit").disabled = true;
        document.querySelector("#payment-spinner").classList.remove("hidden");
        document.querySelector("#spinner-message").textContent = message;
      };
  
      var hideLoading = function() {
        document.querySelector("#payment-spinner").classList.add("hidden");
      }
    }   
  }
}
