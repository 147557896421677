import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static values = {
    form: String,
  };

  connect() {
    const authForm = document.getElementById(this.formValue);

    this.element.addEventListener('click', () => {
        const width = 600;
        const height = 600;
        const left = (screen.width - width) / 2;
        const top = (screen.height - height) / 2;
        
        const authWindow = window.open('', 'authWindow', `width=${width},height=${height},top=${top},left=${left}`);
        authForm.submit();
    });
  }
}
