import { Controller } from "@hotwired/stimulus";

///////////////////////////////////
// Implements sticky blocks
///////////////////////////
export default class extends Controller {
  static values = {
    settings: Object,
  };
  connect() {
    const sticky = this.element;
    const parent = sticky.parentNode;
    

    var fixed = false;
    var smallScreen = false;

    var isSmallScreen = function () {
      return parent.getBoundingClientRect().left < 300;
    };

    // no sticky on small screens.
    if (isSmallScreen()) {
      return;
    }

    if(typeof HSStickyBlock === 'function'){
      
      $('.js-sticky-block').each(function (event) {
        new HSStickyBlock($(this)).init();
      });
      return
    }

    var stickyTopMargin = sticky.getBoundingClientRect().top;
    var settings = this.settingsValue; //JSON.parse(sticky.getAttribute('sticky-block-options'));
    const end = document.querySelector(settings.end);

    var setStyleAfterScroll = function () {
      if(!end){
        return
      }
      if (smallScreen) {
        return;
      }

      var y = window.scrollY;
      var stickyHeight = sticky.clientHeight;
      var endTopMargin = end.getBoundingClientRect().top;

      if (y > stickyTopMargin - settings.offsetTop) {
        if (!fixed) {
          sticky.style.position = "fixed";
          sticky.style.top = `${settings.offsetTop}px`;
          sticky.style.left =
            parent.getBoundingClientRect().left + settings.padding + "px";
          sticky.style.width = parent.clientWidth - settings.padding * 2 + "px";
          fixed = true;
        }
        if (
          fixed &&
          endTopMargin >
            settings.alwaysFixedHeight + settings.contentMarginBottom &&
          endTopMargin <
            stickyHeight + settings.offsetTop + settings.contentMarginBottom
        ) {
          sticky.style.top =
            endTopMargin - settings.contentMarginBottom - stickyHeight + "px";
        }
      } else {
        clearFixed();
      }
    };

    var setStyleAfterResize = function () {
      if (isSmallScreen()) {
        clearFixed();
        smallScreen = true;
      } else {
        smallScreen = false;
        if (fixed) {
          sticky.style.left =
            parent.getBoundingClientRect().left + settings.padding + "px";
          sticky.style.width = parent.clientWidth - settings.padding * 2 + "px";
        } else {
          setStyleAfterScroll();
        }
      }
    };

    var clearFixed = function () {
      if (fixed) {
        sticky.style.position = "";
        sticky.style.top = "";
        sticky.style.left = "";
        sticky.style.width = "";
        fixed = false;
      }
    };

    document.addEventListener("scroll", setStyleAfterScroll);
    window.addEventListener("resize", setStyleAfterResize);
  }
}
