import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static values = {
    url: String
  };

  connect() {
    turboStreamRequest(this.urlValue + `?ts=${new Date().getTime()}`);
  }
}
