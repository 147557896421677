import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static values = {
    source: String
  };

  connect() {
    var ctl = this.element;
    var sourceDomID = this.sourceValue;

    $(ctl).on('click', function(){
      var range = document.createRange();
      range.selectNode(document.getElementById(sourceDomID));
      window.getSelection().removeAllRanges();
      window.getSelection().addRange(range);
      document.execCommand("copy");
      window.getSelection().removeAllRanges();
    
      var evt = $(ctl), original = evt.html(), w = evt.width();
      evt.html('Copied');
      evt.width(w);
      setTimeout(function(){evt.html(original)}, 2000);
    })
  }
}
