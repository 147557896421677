import { Controller } from "@hotwired/stimulus";

///////////////////////////////////
// Implements gradient color
///////////////////////////
export default class extends Controller {
  static values = {
    eventName: { type: String, default: "click" },
    blockClass: String
  };
  connect() {
    var parent = this.element;
    var eventName = this.eventNameValue;
    var blockClass = this.blockClassValue;

    var setGradualColors = function (direction) {
      var func = null,
        arg = "",
        left = 0,
        right = 1;
      if (direction == "radial") {
        func = "radial-gradient";
        left = 1;
        right = 0;
      } else {
        func = "linear-gradient";
        if (direction == "horizontal") {
          arg = "90deg,";
        } else if (direction == "diagonal") {
          arg = "135deg,";
        }
      }

      document.querySelectorAll(`.${blockClass}`).forEach(function (el) {
        var colors = el.parentElement.parentElement
          .querySelector("input")
          .value.split("_");
        el.style.background = `${func}(${arg} ${colors[left]}, ${colors[right]})`;
      });
    };

    setGradualColors(parent.querySelector("input").value);

    parent.querySelectorAll("input").forEach(function (el) {
      el.addEventListener(eventName, event => {
        setGradualColors(el.value);
      });
    });
  }
}
