import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static values = {
    action: String
  };
  connect() {
    var action = this.actionValue;
    var sidebar = document.getElementsByClassName('sidebar')[0];
    
    this.element.addEventListener('click', (event) => {
      if(action == 'show') {
        sidebar.classList.remove('collapsed');
      }else{
        sidebar.classList.add('collapsed');
      }
    });
  }
}
