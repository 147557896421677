import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static values = {
    eventName: { type: String, default: "click" },
    tabClass: String,
    priceClass: String
  };
  connect() {
    var parent = this.element;
    var eventName = this.eventNameValue;
    var tabValue = this.tabClassValue;
    var priceValue = this.priceClassValue;

    var increase = function(e, start, stop, step) {
      var delays = [200, 150, 100, 50, 50, 50]
      if((step > 0 && start <= stop) || (step < 0 && start >= stop)) {
        e.innerHTML = start.toFixed(2);
        setTimeout(function(){increase(e, start + step, stop, step)}, delays[(stop - start) / step]);
      }
    }
    
    parent.querySelectorAll(`.${tabValue}`).forEach(function (el) {
      el.addEventListener(eventName, (event) => {
        parent.querySelectorAll(`.${tabValue}`).forEach(function (e) {
          e.classList.remove("active");
          e.querySelector('input').checked = false;
        });
        el.classList.add("active");
        
        var radio = el.querySelector('input');
        radio.checked = true;

        document.querySelectorAll(`.${priceValue}`).forEach(function (e) {
          var monthly = parseFloat(e.getAttribute('data-monthly')), yearly = parseFloat(e.getAttribute('data-yearly'));
          var step = 1;
          var label = e.parentElement.querySelector('.price-label');
          
          label.style.display = "block";
          if(radio.value == 'monthly'){
            increase(e, yearly, monthly, step)
          } else if(radio.value == 'yearly') {
            increase(e, monthly, yearly, -step)
          } else {
            e.innerHTML = e.getAttribute('data-lifetime');
            label.style.display = "none";
          }

          var desc = e.parentElement.parentElement.querySelector('.price-desc');
          desc.innerHTML = desc.getAttribute(`data-${radio.value}`);
        });
        event.preventDefault();
      });
    });
  }
}
