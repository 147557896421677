import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static values = {
    dom: String,
    showFor: { type: String, default: "" },
  };
  connect() {
    var ctl = this.element;
    var showFor = this.showForValue;
    var dom = $('#' + this.domValue);
    
    $(ctl).change(function(){   
      if(showFor) {
        if($(this).val() == showFor){
          dom.show();
        }else{
          dom.hide();
        }
      } else {
        if($(this).is(":checked")){
          dom.hide();
        }else{
          dom.show();
        }
      }
    })
  }
}
