import { Controller } from "@hotwired/stimulus";

///////////////////////////////////////
// Implements tabs.
// Event name can be changed from click to something else (i.e. change) to implement
// tabs on radio buttons or other elements
////////////////////////////////////
export default class extends Controller {
  static values = {
    eventName: { type: String, default: "click" },
    updateTo: String
  };
  connect() {
    var updateTo = this.updateToValue;
    var el = this.element;

    el.addEventListener(this.eventNameValue, (event) => {
      if(el.tagName == 'INPUT'){
        el.value = updateTo;
      }else{
        el.innerHTML = updateTo;
      }
    });
  }
}
