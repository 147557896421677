import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static values = {
    src: String,
    frame: {type: String, default: ""},
    interval: {type: Number, default: 1000}
  }

  connect() {
    var frameValue = this.frameValue;
    var src = this.srcValue;

    var redirect = function() {
      if(frameValue != "") {
        let frame = document.querySelector(`turbo-frame#${frameValue}`)
        frame.setAttribute('src', src);
        frame.reload();
      }else{
        Turbo.visit(src);
      }
    }

    if(this.intervalValue > 0) {
      this.timeout = setTimeout(() => {
        redirect();
      }, this.intervalValue);
    }else{
      redirect();
    }
  }

  disconnect() {
    if(this.timeout){
      clearTimeout(this.timeout);
    }
  }
}