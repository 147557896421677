import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static values = {
    url: String
  };

  connect() {
    const url = window.location.href;
    var paramsString = new URL(url).search;

    const match = url.match(/\/pay\/(\w+)/);
    if (match && match[1]) {
      paramsString += (paramsString ? '&' : '?') + `channel=${match[1]}`;
    }
     
    if(paramsString){
      turboStreamRequest(this.urlValue + paramsString);
    }
  }
}
