import { Controller } from "@hotwired/stimulus";

///////////////////////////////////
// Shows modal for turbo-frame loaded in modal.
/////////////////////////////////

export default class extends Controller {
  static values = {
    title: {type: String, default: ""}
  }
  connect() {
    this.frameNameValue = 'modal-window';
    let modalHTML = `
      <div style="display: none;" id="modalWindow-${this.frameNameValue}" class="modal-open">
      <div class="modal fade show" style="display: block" tabindex="-1" aria-modal="true" role="dialog">
        <div class="modal-dialog modal-xl" role="document" >
          <div class="modal-content" id="modal_content">
            <div class="modal-header">
              <h5 class="modal-title" id="modal-title">${this.titleValue}</h5>
              <button type="button" class="btn btn-xs btn-icon btn-soft-secondary" data-dismiss="modal" aria-label="Close" id="close-modal" >
                <svg aria-hidden="true" width="10" height="10" viewBox="0 0 18 18" xmlns="http://www.w3.org/2000/svg" >
                <path
                fill="currentColor"
                d="M11.5,9.5l5-5c0.2-0.2,0.2-0.6-0.1-0.9l-1-1c-0.3-0.3-0.7-0.3-0.9-0.1l-5,5l-5-5C4.3,2.3,3.9,2.4,3.6,2.6l-1,1 C2.4,3.9,2.3,4.3,2.5,4.5l5,5l-5,5c-0.2,0.2-0.2,0.6,0.1,0.9l1,1c0.3,0.3,0.7,0.3,0.9,0.1l5-5l5,5c0.2,0.2,0.6,0.2,0.9-0.1l1-1 c0.3-0.3,0.3-0.7,0.1-0.9L11.5,9.5z"
              ></path>
                </svg>
              </button>
            </div>
            <div class="modal-body">
              <turbo-frame id="${this.frameNameValue}"></turbo-frame>
            </div>
          </div>
        </div>
      </div>
      <div class="modal-backdrop fade show"></div>
    </div>`
    this.element.setAttribute("data-turbo-frame", this.frameNameValue);

    var modalElement = document.getElementById(`modalWindow-${this.frameNameValue}`);
    if(!modalElement){
      document.body.insertAdjacentHTML('beforeend', modalHTML);
      modalElement = document.getElementById(`modalWindow-${this.frameNameValue}`);
    }
    var close_event = this.close_event;
    var targetFrame = document.getElementById(this.frameNameValue);

    document.addEventListener("turbo:frame-render", function (el) {
      if (el.target == targetFrame) {
        // The following is a hack to extract the title from the returned page
        // and make it the title of the modal popup. This of course requires that
        // the page returned is a full html page.
        let closeElement = document.getElementById("close-modal");
        closeElement.modalElement = modalElement;
        if (!modalElement.classList.contains("show")) {
          closeElement.returnLoc = window.location.href;
        }

        el.detail.fetchResponse.responseHTML.then((html) => {
          let dom = new DOMParser().parseFromString(html, "text/html");
          let title = dom.querySelector("title");
          if(title){
            document.getElementById("modal-title").innerHTML = title.innerHTML;
          }else{
            let frame = dom.querySelector("turbo-frame");
            if(frame) {
              document.getElementById("modal-title").innerHTML = frame.getAttribute("title");
            }
          }
          modalElement.classList.add("show");
          // TODO - Figure out why this is needed. Can't it just use show?
          modalElement.style.display = "block";
        });
      }
    });
    document
      .getElementById("close-modal")
      .addEventListener("click", close_event);
  }
  close_event(evt) {
    evt.currentTarget.modalElement.classList.remove("show");
    evt.currentTarget.modalElement.style.display = "none";
    window.history.pushState({}, "", evt.currentTarget.returnLoc);
  }
}
