import { Controller } from "@hotwired/stimulus";

///////////////////////////////////////
// Implements tabs.
// Event name can be changed from click to something else (i.e. change) to implement
// tabs on radio buttons or other elements
////////////////////////////////////
export default class extends Controller {
  static values = {
    target: String,
    event: {type: String, default: "click"}
  };

  connect() {
    const parent = this.element;
    parent.style.cursor = 'pointer';
    const target = this.targetValue;

    parent.innerHTML = '<i class="fas fa-angle-right fa-fw"></i>' + parent.innerHTML;

    this.element.addEventListener(this.eventValue, (event) => {
      var arrow = parent.getElementsByClassName('fas')[0];
      arrow.classList.toggle('fa-angle-right');
      arrow.classList.toggle('fa-angle-down');

      $('#' + target).slideToggle();
      event.preventDefault();
    });
  }
}
