import { Controller } from "@hotwired/stimulus";

///////////////////////////////////////
// Implements search products.
////////////////////////////////////
export default class extends Controller {
  static values = {
    url: String
  };
  connect() {
    $(this.element).autocomplete({
      minChars: 2,
      deferRequestBy: 600,
      showNoSuggestionNotice: true,
      serviceUrl: this.urlValue,
      autoSelectFirst: true,
      formatResult: function(suggestion, currentValue) {
        var original = $.Autocomplete.defaults.formatResult(suggestion, currentValue);
        if(suggestion.image){
          return `<div class="d-flex align-items-center"><img src="${suggestion.image}" class="mr-2">${original}</div>`
        }else{
          return original;
        }
      },
    });
  }
}
