// See https://stackoverflow.com/questions/68624668/how-can-i-submit-a-form-on-input-change-with-turbo-streams

///////////////////////////////////
// Submits a form when handle is called
// use data-action='submit-on-change#handle' on form elements to trigger submittion on change
///////////////////////////////
import { Controller } from "@hotwired/stimulus";
let debounce = require("lodash/debounce");

export default class extends Controller {
  static values = {
    loadingSelector: { type: String, default: ".qr-code-container .spinner"},
    loadingText: {type: String, default: ""}
  };

  connect() {
   
    this.handle = debounce(this._handle, 300).bind(this);
    this.textHandle = debounce(this._handle, 1000).bind(this); // Long debounce for text input
  }
  _handle(event) {
    event.preventDefault();
    
    var f = event.target.form;
    if(f){
      var loadings = document.querySelectorAll(this.loadingSelectorValue);
      loadings.forEach((loading) => {
        loading.style.display = "block";
        if(this.loadingTextValue != "") {
          loading.innerHTML = this.loadingTextValue;
        }
      });
      f.requestSubmit();
    }
  }
}
