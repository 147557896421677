import { Controller } from "@hotwired/stimulus";

///////////////////////////////////
// Implements color picker
///////////////////////////
export default class extends Controller {
  static values = {
    url: String
  }
  
  connect() {
    const parent = this.element;
    const url = this.urlValue;

    parent.classList.add('clickable');
    parent.addEventListener("click", event => {
      if(event.target.tagName != 'LABEL' && event.target.tagName != 'INPUT'){
        var checkbox = parent.querySelector('input[type="checkbox"]');
        checkbox.checked = !checkbox.checked;
      }
      if(event.target.tagName != 'INPUT'){
        turboStreamRequest(url);
      }
    });
  }
}
