import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static values = {
    tabs: {type: String, default: ''}
  };

  connect() {
    var tabs = this.tabsValue;

    document.querySelectorAll('.style-tab-link.active').forEach(function (e) {
      e.classList.remove("active");
    });

    tabs.split(",").forEach(function (e) {
      var dom = document.querySelector(`.link-${e}`);
      if(dom){
        dom.classList.add("active");
      }
    });
  }
}
