import { Controller } from "@hotwired/stimulus";

///////////////////////////////////////
// Implements tabs.
// Event name can be changed from click to something else (i.e. change) to implement
// tabs on radio buttons or other elements
////////////////////////////////////
export default class extends Controller {
  static values = {
    eventName: { type: String, default: "click" },
    inputField: { type: String, default: "" },
    linkClass: String,
    paneClass: {type: String, default: "tab-pane"}
  };

  connect() {
    var parent = this.element;
    var eventName = this.eventNameValue;
    var linkClass = this.linkClassValue;
    var paneClass = this.paneClassValue;
    var inputField = this.inputFieldValue;
    
    parent.querySelectorAll(`.${this.linkClassValue}`).forEach(function (el) {
      el.addEventListener(eventName, (event) => {
        parent.querySelectorAll(`.${linkClass}.active`).forEach(function (e) {
          e.classList.remove("active");
        });
        el.classList.add("active");

        parent.querySelectorAll(`.${paneClass}.show`).forEach(function (e) {
          e.classList.remove("show");
        });
        var contentId = el.getAttribute("aria-controls");
        var d = document.getElementById(contentId);
        if(d){
          d.classList.add("show");
        }

        if(inputField != ""){
          var input = document.getElementById(inputField);
          if(input){
            input.value = contentId;
          }
        }

        event.preventDefault();
      });
    });
  }
}