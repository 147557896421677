import { Controller } from "@hotwired/stimulus";

///////////////////////////////////////
// Implements tabs.
// Event name can be changed from click to something else (i.e. change) to implement
// tabs on radio buttons or other elements
////////////////////////////////////
export default class extends Controller {
  static values = {
    container: String,
    tab: String
  };

  connect() {
    var activeTab = document.getElementById(this.tabValue);
    if (activeTab) {
      var tabContainer = document.getElementById(this.containerValue);
      var tabClass = tabContainer.getAttribute("data-tab-link-class-value");

      tabContainer.querySelectorAll(`.${tabClass}.active`).forEach(function (e) {
        e.classList.remove("active");
      });
      tabContainer.querySelectorAll(`.tab-pane.show`).forEach(function (e) {
        e.classList.remove("show");
      });

      activeTab.classList.add("active");

      var content = document.getElementById(
        activeTab.getAttribute("aria-controls")
      );
      var panelClass = content.classList[0];
      document.querySelectorAll(`.${panelClass}.show`).forEach(function (e) {
        e.classList.remove("show");
      });
      content.classList.add("show");
    }
  }
}