import { Controller } from "@hotwired/stimulus";

///////////////////////////////////
// Implements preview updated
///////////////////////////
export default class extends Controller {
  connect() {
    var loadings = document.querySelectorAll(".qr-code-container .spinner");
    loadings.forEach((loading) => {
      loading.style.display = "none";
    });
  }
}
