import { Controller } from "@hotwired/stimulus";

///////////////////////////////////
// Implements color picker
///////////////////////////
export default class extends Controller {
  static values = {
    url: String
  }

  connect() {
    const parent = this.element;
    const url = this.urlValue;

    parent.classList.add('clickable');
    parent.addEventListener("click", event => {
      if(event.target.tagName != 'A' && event.target.parentNode.tagName != 'A'){
        Turbo.visit(url)
      }
    });
  }
}
