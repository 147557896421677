import { Controller } from "@hotwired/stimulus";

///////////////////////////////////////
// Implements uploading logo.
////////////////////////////////////
export default class extends Controller {
  static values = {
    field: String
  };

  connect() {
    var obj = this.element;
    var uploader = obj.querySelector('.uploader');
    var uploaded = obj.querySelector('.uploaded');
    var uploadedImg = uploaded.querySelector('img');
    var base64 = obj.querySelector('#' + this.fieldValue);

    var setLogo = function(base64){
      uploader.style.display = 'none';
      uploaded.style.display = 'inline-block'; 
      uploadedImg.setAttribute('src', base64);
      obj.querySelectorAll('input[type=radio]').forEach(function(el) {
        el.checked = false;
      });
    }

    var deleteLogo = function(){
      uploader.style.display = 'inline-block'; 
      uploaded.style.display = 'none';
      uploadedImg.setAttribute('src', '');

      base64.value = null;
    }
  
    var readFile = function(){
      if (this.files && this.files[0]) {
        var imageFile = this.files[0];
        const maxSize = 1024 * 1024;
  
        if(imageFile.type.split('/')[0] != 'image'){
          alert('Only image file types are allowed!');
          return
        }
        if(imageFile.size > maxSize){
          alert("File can't be larger than 1 megabyte. Please choose a smaller file.");
          return
        }
  
        var reader = new FileReader();
        reader.addEventListener("load", function(e) {
          var img = document.createElement("img");
          img.onload = function (event) {
              var canvas = document.createElement("canvas");
              var ctx = canvas.getContext("2d");
              canvas.width = img.width / 3;
              canvas.height = img.height / 3;
              ctx.drawImage(img, 0, 0, canvas.width, canvas.height);
  
              var imgData = canvas.toDataURL(imageFile.type);

              setLogo(imgData);
              base64.value = `${imgData}${base64.value}`;
          }
          img.src = e.target.result;
        }); 
        reader.readAsDataURL(imageFile);

        if (imageFile.type === 'image/svg+xml') {
          var readerSVG = new FileReader();
          readerSVG.onload = (e) => {
            base64.value = `${base64.value}${e.target.result}`;
          };
          readerSVG.readAsText(imageFile);
        }
      }
    }

    obj.querySelector('.img_file').addEventListener('change', readFile);
    obj.querySelector('.delete-logo').addEventListener('click', function(){
      deleteLogo();
    });

    obj.querySelectorAll('.radio-image img').forEach(function(el) {
      el.addEventListener('click', function(){
        setLogo(el.getAttribute('src'))
      });
    });
  }
}
