import { Controller } from "@hotwired/stimulus";

//////////////////////////
// Simply sends page to a URL (using Turbo) after a delay
///////////////////////
export default class extends Controller {
  handle(event) {
    var url = event.params.url;
    setTimeout(() => {
      // Turbo.visit(url);
      window.location.href = url;
    }, event.params.delay);
  }
}
